import moment from "moment";
import React, { Suspense, useEffect, useState, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import ImageLoader from "./assets/logoGorfR.png";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
//LinkTree
import CheckoutForm from "./components/Main/components/User/components/Purchase/CheckoutForm";
const Loader = () => (
  <div className="App">
    <img className="loader" alt="logo" src={ImageLoader} />
  </div>
);

const Auth = lazy(() => import("./components/Auth"));
const Main = lazy(() => import("./components/Main"));
const Tip = lazy(() => import("./components/Tip"));

function App() {
  window.countPerPage = process.env.REACT_APP_COUNT_PER_PAGE || 5;
  const token = window.localStorage.getItem("gorf_pay_token");
  const [logined, setLogined] = useState(!!token);
  const history = useHistory();
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  const handleResize = (e) => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem("colorMode"))) {
      document.querySelector("body").classList.remove("backColor");
    } else {
      document.querySelector("body").classList.add("backColor");
    }
  }, []);

  useEffect(() => {
    const onLogout = (event) => {
      setLogined(false);
      window.localStorage.removeItem("gorf_pay_token");
      window.localStorage.removeItem("gorf_pay_role");

      return history.push({
        pathname: "/",
        state: {},
      });
    };

    const onLogin = (event) => {
      setLogined(true);

      return history.push({
        pathname: "/",
        state: {},
      });
    };

    if (window) {
      window.addEventListener("logined", onLogin);
      window.addEventListener("logout", onLogout);
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("logined", onLogin);
      window.removeEventListener("logout", onLogout);
      window.removeEventListener("resize", handleResize);
    };
  }, [history]);

  if (!logined) {
    return (
      <Suspense fallback={<Loader />}>
        <Auth />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loader />} className={"App"}>
      <main className="App-content">
        {
          <Switch>
            <Route exact path="/tip/:channelId">
              <Tip />
            </Route>
            <Route path="/stripe">
              <CheckoutForm />
            </Route>
            <Route path="/">
              <Main innerWidth={innerWidth} />
            </Route>
          </Switch>
        }
      </main>
      <Segment.Group horizontal id={"footer"}>
        <Segment textAlign={"left"}>
          <Link target={"_blank"} to={{ pathname: "https://gorf.com/contact" }}>
            Contact
          </Link>
        </Segment>
        <Segment className="footer__brand" textAlign={"center"}>
          {moment().format("YYYY")} — GORF ©
        </Segment>
        <Segment></Segment>
      </Segment.Group>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Suspense>
  );
}

export default App;
