import React from 'react';
import {Grid, Header, Image, Message} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';

import StripeForm from './form';

import './style.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function CheckoutForm() {
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret: new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    ),
    appearance,
  };

  return (
    <Grid textAlign="center" style={{height: '100vh'}} verticalAlign="middle" className={'signIn'}>
      <Grid.Column style={{maxWidth: 450}}>
        <Image src={process.env.REACT_APP_ENV ? `/${process.env.REACT_APP_ENV}logo.png` : '/logo.png'}
               size="medium" centered/>
        <Header as="h1" color="teal" textAlign="center" style={{color: '#ff0000', fontSize: '32px'}}>
          GORF Pay
        </Header>
        <Elements options={options} stripe={stripePromise}>
          <StripeForm />
          <Message>
            <Link to="/buy">{'<< go back to purchases'}</Link>
          </Message>
        </Elements>
      </Grid.Column>
    </Grid>
  );
}
